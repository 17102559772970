<template>
 <div>
  <svg viewBox="0 0 183 183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="userSpaceOnUse" id="Naamloos_verloop_4" x1="25.38" x2="157.62" y1="91.5" y2="91.5">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g data-name="Laag 2" id="Laag_2">
    <g data-name="Laag 1" id="Laag_1-2">
     <g id="icon-challenge">
      <rect :fill="background" height="183" width="183"/>
      <path d="M155.8,149.34a4,4,0,0,1-2.19.65H136.84l0-.07a10,10,0,0,0-.11-10.06l-14-23.34,4.83-7.4a4,4,0,0,1,5.53-1.16,4,4,0,0,1,1.17,1.16L157,143.8A4,4,0,0,1,155.8,149.34ZM59.65,115.71l-4.3-6.59A4,4,0,0,0,54.18,108a4,4,0,0,0-5.53,1.16L26,143.8A4,4,0,0,0,29.38,150H45.15l0-.07a10,10,0,0,1,.11-10.06Zm63.59-80.47a46,46,0,0,1-5.74.48,45.62,45.62,0,0,1-9.44-1.41A48.54,48.54,0,0,0,98.32,33c-6.79,0-9.09,1.4-9.34,1.56l-.48.33V73.06a10,10,0,0,1,2.5-.32,10.12,10.12,0,0,1,3,.47V56.85a34,34,0,0,1,4.28-.23c3.16,0,6.11,1.15,9.24,1.8a49.45,49.45,0,0,0,10.06,1.32,45.79,45.79,0,0,0,5.61-.46c.87-.1,1.62-.2,2.32-.31V34.89C125,35,124.11,35.13,123.24,35.24ZM90.37,100.45l11.83-8.71L95.29,80.17a5,5,0,0,0-8.59,0l-7,11.69.34.27Zm42.11,42-12.69-21.25L105.3,96.93l-13.23,9.73a3,3,0,0,1-1.78.58,2.93,2.93,0,0,1-1.88-.67L76.62,97.06,62.68,120.37l-13.17,22a5,5,0,0,0,4.3,7.57h74.37A5,5,0,0,0,132.48,142.42Z" data-name="Group 502" id="Group_502" style="fill:url(#Naamloos_verloop_4);"/>
     </g>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>